import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';

import usersReducer from '../features/Users/UsersSlice';
import ediContainerReducer from '../features/EDIContainer/EDIContainerSlice';
import businessAreasReducer from '../features/BusinessAreas/BusinessAreasSlice';
import clientsReducer from '../features/Clients/ClientsSlice';
import clientDetailsReducer from '../features/ClientDetails/ClientDetailsSlice';
import clientPartnerDetailsReducer from '../features/ClientPartnerDetails/ClientPartnerDetailsSlice';
import ediStandardsReducer from '../features/EDIStandards/EDIStandardsSlice';
import productsReducer from '../features/Products/ProductsSlice';
import transactionsReducer from '../features/Transactions/TransactionsSlice';
import mapsReducer from '../features/Maps/MapsSlice';
import tradingPartnersReducer from '../features/TradingPartners/TradingPartnersSlice';
import tradingPartnerDetailsReducer from '../features/TradingPartnerDetails/TradingPartnerDetailsSlice';
import pipelineDetailsReducer from '../features/PipelineDetails/PipelineDetailsSlice';
import pipelinesReducer from '../features/Pipelines/PipelinesSlice';
import billingReducer from '../features/Billing/BillingSlice';
import mapDetailsReducer from '../features/MapDetails/MapDetailsSlice';
import billingDetailsReducer from '../features/BillingDetails/BillingDetailsSlice';
import pricingReducer from '../features/Pricing/PricingSlice';
import pricingDetailsReducer from '../features/PricingDetails/PricingDetailsSlice';
import processesReducer from '../features/Processes/ProcessesSlice';
import erpTransactionShemasReducer from '../features/ErpTransactionSchemas/ErpTransactionSchemasSlice';
import currencyReducer from '../features/Currencies/CurrencySlice';
import eventLogsReducer from '../features/EventLogs/EventLogsSlice';
import systemConfigurationReducer from '../features/SystemConfiguration/SystemConfigurationSlice';
import notificationFormatterReducer from '../features/NotificationFormatters/NotificationFormattersSlice';
import errorFormatterReducer from '../features/EDIContainer/EDIContainerSlice';
import messageLogDetailsReducer from '../features/MessageLogDetails/MessageLogDetailsSlice';
import documentArchivesReducer from '../features/DocumentArchives/DocumentArchivesSlice';
import crossReferenceTableReducer from '../features/CrossReferenceTable/CrossReferenceTableSlice';
import postProcessingBlobsReducer from '../features/PostProcessingBlobs/PostProcessingBlobsSlice';
import ediStaticFilesReducer from '../features/EDIStaticFiles/EDIStaticFilesSlice';
import counterTableReducer from '../features/CounterTable/CounterTableSlice';

export const rootReducer = combineReducers({
    users: usersReducer,
    ediContainer: ediContainerReducer,
    businessAreas: businessAreasReducer,
    clients: clientsReducer,
    clientDetails: clientDetailsReducer,
    clientPartnerDetails: clientPartnerDetailsReducer,
    ediStandards: ediStandardsReducer,
    products: productsReducer,
    transactions: transactionsReducer,
    mapDetails: mapDetailsReducer,
    maps: mapsReducer,
    tradingPartners: tradingPartnersReducer,
    tradingPartnerDetails: tradingPartnerDetailsReducer,
    pipelineDetails: pipelineDetailsReducer,
    pipelines: pipelinesReducer,
    billing: billingReducer,
    billingDetails: billingDetailsReducer,
    pricings: pricingReducer,
    pricingDetails: pricingDetailsReducer,
    processes: processesReducer,
    erpTransactionShemas: erpTransactionShemasReducer,
    eventLogs: eventLogsReducer,
    currencies: currencyReducer,
    systemConfiguration: systemConfigurationReducer,
    notificationFormatters: notificationFormatterReducer,
    errorFormatters: errorFormatterReducer,
    messageLogDetails: messageLogDetailsReducer,
    documentArchives: documentArchivesReducer,
    crossReferenceTable: crossReferenceTableReducer,
    postProcessingBlobs: postProcessingBlobsReducer,
    ediStaticFiles: ediStaticFilesReducer,
    counterTable: counterTableReducer,
});

const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, undefined, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export default store;
