import { AppBar, Box, Collapse, Toolbar, IconButton, InputBase, Grow, Avatar, Popper, Paper, MenuList, MenuItem, ClickAwayListener, ListItemIcon, InputAdornment, Typography, Grid, Divider } from '@mui/material';
import { alpha, styled } from '@mui/system';
import logo from '../icons/logo.svg';
import ApteanBlackLogo from '../icons/ApteanBlackLogo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout'
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import WorkIcon from '@mui/icons-material/Work';
import PartnersIcon from '@mui/icons-material/SafetyDivider';
import BusinessIcon from '@mui/icons-material/Business';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DevicesIcon from '@mui/icons-material/Devices';
import TransformIcon from '@mui/icons-material/Transform';
import PipelineIcon from '@mui/icons-material/Insights';
import ArchiveIcon from '@mui/icons-material/Inventory';
import MapIcon from '@mui/icons-material/Map';
import BillingIcon from "@mui/icons-material/AccountBalance";
import PricingIcon from "@mui/icons-material/ReceiptLong";
import ProcessIcon from "@mui/icons-material/AccountTree";
import SchemaIcon from "@mui/icons-material/Schema";
import LogsIcon from '@mui/icons-material/ListAlt';
import CurrencyIcon from '@mui/icons-material/Payments';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NotificationIcon from '@mui/icons-material/Notifications';
import SummarizeIcon from '@mui/icons-material/Summarize';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MemoryIcon from '@mui/icons-material/Memory';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SyncIcon from "@mui/icons-material/Sync";
import FileIcon from '@mui/icons-material/Description';
import CounterIcon from '@mui/icons-material/FormatListNumbered';
import { MouseEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Viewer } from '../util/Constants';
import { UserRole } from '../gql-types.generated';

const PrimaryBar = styled(AppBar)(({ theme }) => ({
    borderBottom: `solid 1px ${theme.palette.border.main}`,
}));

const ProductLogo = styled('img')((props) => ({
    height: '27px',
    marginTop: '10px',
    marginRight: '10px',
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    height: '40px',
    flexGrow: 1,
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
    padding: theme.spacing(1, 1, 1, 2),
    width: '100%',
    height: '100%',

}));

const MenuPopper = styled(Popper)((props) => ({
    zIndex: '125',
}));

const UserInfo = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(1, 2, 1, 2),
}));

const SteppedMenu = styled(MenuItem)(({ theme }) => ({
    paddingLeft: theme.spacing(4),
}));

const FixedWidthPaper = styled(Paper)(({ theme }) => ({
    width: '280px',
}));

interface PrimaryAppBarProps {
    handleSearch: () => void;
    handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleLogoutClick: () => void;
    handleBillingReport: () => void;
    handlePortalSync: () => void;
    userContext: Viewer | undefined;
}

const PrimaryAppBar: React.FC<PrimaryAppBarProps> = props => {
    const navigate = useNavigate();
    const { handleSearch, handleSearchChange, handleLogoutClick, handleBillingReport, handlePortalSync, userContext } = props;
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const [navMenuOpen, setNavMenuOpen] = useState(false);
    const [navSetupOpen, setNavSetupOpen] = useState(false);
    const [navAdminOpen, setNavAdminOpen] = useState(false);
    const userAnchorRef = useRef<HTMLButtonElement>(null);
    const navMenuAnchorRef = useRef<HTMLButtonElement>(null);
    const isViewerAdmin = userContext?.role && userContext.role === UserRole.Admin;
    const isViewerEditor = userContext?.role && userContext.role === UserRole.Editor;
    const isSetupViewer = isViewerEditor || isViewerAdmin;

    const handleUserMenuToggle = () => {
        setUserMenuOpen((userPrevOpen) => !userPrevOpen);
    };
    const handleNavMenuToggle = () => {
        setNavMenuOpen((navPrevOpen) => !navPrevOpen);
    }
    const handleUserMenuClose = (event: Event | SyntheticEvent) => {
        if (userAnchorRef.current &&
            userAnchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setUserMenuOpen(false);
    };
    const handleNavMenuClose = (event: Event | SyntheticEvent) => {
        if (navMenuAnchorRef.current &&
            navMenuAnchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setNavMenuOpen(false);
    };
    // return focus to the button when we transitioned from !open -> open
    const userPrevOpen = useRef(userMenuOpen);
    useEffect(() => {
        if (userPrevOpen.current === true && userMenuOpen === false) {
            userAnchorRef.current!.focus();
        }

        userPrevOpen.current = userMenuOpen;
    }, [userMenuOpen]);
    // return focus to the button when we transitioned from !open -> open
    const navPrevOpen = useRef(navMenuOpen);
    useEffect(() => {
        if (navPrevOpen.current === true && navMenuOpen === false) {
            userAnchorRef.current!.focus();
        }

        navPrevOpen.current = navMenuOpen;
    }, [navMenuOpen]);

    const handleNavigate = (event: MouseEvent<HTMLElement>) => {
        if (event.currentTarget && event.currentTarget.id) {
            let route;
            switch (event.currentTarget.id) {
                case "navMenu-Home":
                    route = '/home';
                    break;
                case "navMenu-Users": ;
                    route = '/users';
                    break;
                case "navMenu-Clients":
                    route = '/clients';
                    break;
                case "navMenu-TradingPartners":
                    route = '/tradingpartners';
                    break;
                case "navMenu-EventLogs":
                    route = '/eventlogs';
                    break;
                case "navMenu-DocumentArchives":
                    route = '/archives';
                    break;
                case "navMenu-BusinessAreas":
                    route = '/businessareas';
                    break;
                case "navMenu-EdiStandards":
                    route = '/edistandards';
                    break;
                case "navMenu-Products":
                    route = '/products';
                    break;
                case "navMenu-Transactions":
                    route = '/transactions';
                    break;
                case "navMenu-Pipelines":
                    route = '/pipelines';
                    break;
                case "navMenu-Maps":
                    route = '/maps';
                    break;
                case "navMenu-Billing":
                    route = '/billing';
                    break;
                case "navMenu-Pricing":
                    route = '/pricing';
                    break;
                case "navMenu-Processes":
                    route = '/processes';
                    break;
                case "navMenu-ErpTransactionSchemas":
                    route = '/erptransactionschemas';
                    break;
                case "navMenu-Currency":
                    route = '/currencies';
                    break;
                case "navMenu-Configuration":
                    route = '/config';
                    break;
                case "navMenu-NotificationFormatters":
                    route = '/notificationformatters';
                    break;
                case "navMenu-CounterTable":
                    route = "/counterTable";
                    break;
                case "navMenu-CrossReferenceTable":
                    route = "/crossReferenceTable";
                    break;
                case "navMenu-PostProcessingAssemblies":
                    route = "/postprocessingassemblies";
                    break;
                case "navMenu-StaticResources":
                    route = "/staticresources";
                    break;
            }
            if (route) {
                navigate(route);
            }
        }
        setNavMenuOpen(false);
    };

    const handleBillingReportClick = (event: MouseEvent<HTMLElement>) => {
        handleBillingReport();
    };

    const handleSyncPortalClick = (event: MouseEvent<HTMLElement>) => {
        handlePortalSync();
    };

    const handleSetup = (event: MouseEvent<HTMLElement>) => {
        setNavSetupOpen(!navSetupOpen);
        if (navAdminOpen) setNavAdminOpen(false);
    };

    const handleAdmin = (event: MouseEvent<HTMLElement>) => {
        setNavAdminOpen(!navAdminOpen);
        if (navSetupOpen) setNavSetupOpen(false);
    };

    let userIcon = <PersonIcon />;
    if (userContext?.picture) {
        userIcon = <Avatar src={userContext.picture}></Avatar>
    }
    let disableMenu = true;
    if (userContext?.role) {
        disableMenu = false;
    }
    return (
        <Box sx={{ flexGrow: 1 }} id="primary-app-bar">
            <PrimaryBar position="static" color="inherit">
                <Toolbar >
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={handleNavMenuToggle}
                        ref={navMenuAnchorRef}
                        disabled={disableMenu}
                    >
                        <MenuIcon />
                    </IconButton>
                    <MenuPopper
                        open={navMenuOpen}
                        anchorEl={navMenuAnchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                                <FixedWidthPaper>
                                    <ClickAwayListener onClickAway={handleNavMenuClose}>
                                        <MenuList
                                            autoFocusItem={navMenuOpen}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                        >
                                            <MenuItem onClick={handleNavigate} id="navMenu-Home" >
                                                <ListItemIcon>
                                                    <HomeIcon fontSize="small" />
                                                </ListItemIcon>
                                                Home
                                            </MenuItem>
                                            <MenuItem onClick={handleNavigate} id="navMenu-Clients" >
                                                <ListItemIcon>
                                                    <WorkIcon fontSize="small" />
                                                </ListItemIcon>
                                                Clients
                                            </MenuItem>
                                            <MenuItem onClick={handleNavigate} id="navMenu-TradingPartners" >
                                                <ListItemIcon>
                                                    <PartnersIcon fontSize="small" />
                                                </ListItemIcon>
                                                Trading Partners
                                            </MenuItem>
                                            {isSetupViewer && (
                                                <MenuItem onClick={handleNavigate} id="navMenu-Maps" >
                                                    <ListItemIcon>
                                                        <MapIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    Transaction Maps
                                                </MenuItem>
                                            )}
                                            <MenuItem onClick={handleNavigate} id="navMenu-EventLogs" >
                                                <ListItemIcon>
                                                    <LogsIcon fontSize="small" />
                                                </ListItemIcon>
                                                System Event Logs
                                            </MenuItem>
                                            <MenuItem onClick={handleNavigate} id="navMenu-DocumentArchives" >
                                                <ListItemIcon>
                                                    <ArchiveIcon fontSize="small" />
                                                </ListItemIcon>
                                                Document Archives
                                            </MenuItem>
                                            {isSetupViewer && (
                                                <MenuItem onClick={handleSetup} >
                                                    <ListItemIcon>
                                                        <DisplaySettingsIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    Setup
                                                    {navSetupOpen ? <ExpandLess /> : <ExpandMore />}
                                                </MenuItem>
                                                
                                            )}
                                            {isSetupViewer && (
                                                <Collapse in={navSetupOpen} timeout="auto" unmountOnExit>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-BusinessAreas" >
                                                        <ListItemIcon>
                                                            <BusinessIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Business Areas
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-Products" >
                                                        <ListItemIcon>
                                                            <DevicesIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        ERP Products
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-EdiStandards" >
                                                        <ListItemIcon>
                                                            <DataObjectIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        EDI Standards
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-Transactions" >
                                                        <ListItemIcon>
                                                            <TransformIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        EDI Transactions
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-Processes" >
                                                        <ListItemIcon>
                                                            <ProcessIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Legacy EDI Processes
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-Pipelines" >
                                                        <ListItemIcon>
                                                            <PipelineIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Pipelines
                                                    </SteppedMenu>
                                                   <SteppedMenu onClick={handleNavigate} id="navMenu-ErpTransactionSchemas" >
                                                        <ListItemIcon>
                                                            <SchemaIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        ERP Transaction Schemas
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-Currency" >
                                                        <ListItemIcon>
                                                            <CurrencyIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Currencies
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-NotificationFormatters" >
                                                        <ListItemIcon>
                                                            <NotificationIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Notification Formatters
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-Configuration" >
                                                        <ListItemIcon>
                                                            <ManageAccountsIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Configuration
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-CounterTable" >
                                                        <ListItemIcon>
                                                            <CounterIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Counter Table
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-CrossReferenceTable" >
                                                        <ListItemIcon>
                                                            <BackupTableIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Cross Reference Table
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-StaticResources" >
                                                        <ListItemIcon>
                                                            <FileIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Static Resources
                                                    </SteppedMenu>
                                                </Collapse>
                                            )}
                                                                                        
                                            {isViewerAdmin && (
                                                <MenuItem onClick={handleAdmin} >
                                                <ListItemIcon>
                                                    <AdminPanelSettingsIcon fontSize="small" />
                                                </ListItemIcon>
                                                Administration
                                                {navAdminOpen ? <ExpandLess /> : <ExpandMore />}
                                            </MenuItem>
                                            )}
                                            {isViewerAdmin && (
                                                <Collapse in={navAdminOpen} timeout="auto" unmountOnExit>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-Users" >
                                                        <ListItemIcon>
                                                            <PeopleIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Users
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-Billing" >
                                                        <ListItemIcon>
                                                            <BillingIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Billing
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-Pricing" >
                                                        <ListItemIcon>
                                                            <PricingIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Pricing
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleBillingReportClick} id="navMenu-BillingReport" >
                                                        <ListItemIcon>
                                                            <SummarizeIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Billing Report
                                                    </SteppedMenu>
                                                    <SteppedMenu onClick={handleNavigate} id="navMenu-PostProcessingAssemblies" >
                                                        <ListItemIcon>
                                                            <MemoryIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Post Processing Assembly
                                                    </SteppedMenu>
                                                    {
                                                    // <SteppedMenu onClick={handleSyncPortalClick} id="navMenu-SyncPortal" >
                                                    //     <ListItemIcon>
                                                    //         <SyncIcon fontSize="small" />
                                                    //     </ListItemIcon>
                                                    //     Synchronize Portal
                                                    // </SteppedMenu>
                                                    }
                                                </Collapse>
                                            )}
                                        </MenuList>
                                    </ClickAwayListener>
                                </FixedWidthPaper>
                            </Grow>
                        )}
                    </MenuPopper>
                    <a href="/" data-cy="home-logo">
                        <ProductLogo src={ApteanBlackLogo} />
                        <ProductLogo src={logo} alt="Aptean Logo" />
                    </a>
                    <Box sx={{ flexGrow: 6 }} />
                    {/* Hide the Search until it is actually being used and not just a placeholder
                    <Search >
                        <SearchInput
                            placeholder="Enter keywords to search..."
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={handleSearchChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton size="medium" color="default" onClick={handleSearch} data-cy="user-account-icon">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Search>
                    */}      
                    <IconButton
                        size="medium"
                        color="default"
                        onClick={handleUserMenuToggle}
                        data-cy="user-account-icon"
                        ref={userAnchorRef}
                    >
                        {userIcon}
                    </IconButton>
                    <MenuPopper
                        open={userMenuOpen}
                        anchorEl={userAnchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                                {/* <SessionMenu handleLogoutClick={handleLogoutClick} userContext={userContext} /> */}
                                <Paper>
                                    <ClickAwayListener onClickAway={handleUserMenuClose}>
                                        <MenuList
                                            autoFocusItem={userMenuOpen}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                        >
                                            <UserInfo item container md={12} spacing={2}>
                                                <Grid item md={12}>
                                                    <Typography variant="h6">{userContext?.name}</Typography>
                                                    <Typography variant="body2">{userContext?.email}</Typography>
                                                </Grid>
                                                <Grid item md={12}>
                                                    <Divider />
                                                </Grid>
                                            </UserInfo>

                                            <MenuItem onClick={handleLogoutClick}>
                                                <ListItemIcon>
                                                    <LogoutIcon fontSize="small" />
                                                </ListItemIcon>
                                                Logout
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </MenuPopper>
                </Toolbar>
            </PrimaryBar>
        </Box>
    );
};

export default PrimaryAppBar;